import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';

interface Props {
  url: string;
  searchParams?: { [k: string]: string };
  skip?: boolean;
  debounceTime?: number;
}

interface ReturnType {
  data: any;
  isLoading: boolean;
  error: boolean;
}

export const useExternalFetch = ({ url, searchParams, skip, debounceTime }: Props): ReturnType => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetchData = useCallback(
    async (params) => {
      try {
        setIsLoading(true);
        const res = await axios.get(url, { params });
        setData(res.data);
        setIsLoading(false);
        setError(false);
      } catch (e) {
        setIsLoading(false);
        setError(true);
      }
    },
    [url],
  );

  const debouncedFetchData = useMemo(
    () => debounce((params) => fetchData(params), debounceTime || 300),
    [fetchData, debounceTime],
  );

  useEffect(() => {
    if (!skip) debouncedFetchData(searchParams);
  }, [debouncedFetchData, debounceTime, skip, searchParams]);

  return { data, isLoading, error };
};
