import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Label, Message } from '@naf/input';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import ExternalTypeahead from './Typeahead/ExternalTypeahead';

export const Address = <T extends FieldValues>({
  control,
  defaultValue,
  errorMessage,
  name,
  isDisabled,
  handleChange,
  placeholder,
  rules,
}: UseControllerProps<T> & {
  errorMessage?: string;
  isDisabled?: boolean;
  handleChange?: (o: { [k: string]: string }) => void;
  placeholder: string;
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onChange, value } }) => (
      <FieldWrapper>
        <Label>Adresse</Label>
        <ExternalTypeahead
          dataMap={(data) =>
            data.adresser.map((address) => ({
              ...address,
              value: address.adressetekst,
              label: address.adressetekst,
              dropdownLabel: `${address.adressetekst}, ${address.postnummer} ${address.poststed}`,
            }))
          }
          url="https://ws.geonorge.no/adresser/v1/sok"
          searchParams={{
            fuzzy: 'true',
            utkoordsys: '4258',
            treffPerSide: '10',
            side: '0',
            asciiKompatibel: 'true',
          }}
          onChange={onChange}
          handleSelect={(option) => {
            if (option.value !== value) onChange(option.value);
            if (handleChange) {
              handleChange(option);
            }
          }}
          error={!!errorMessage}
          value={value}
          disabled={isDisabled}
          placeholder={placeholder}
          autoComplete="address-line1"
        />
        {errorMessage && <Message error>{errorMessage}</Message>}
      </FieldWrapper>
    )}
  />
);

const FieldWrapper = styled.div`
  margin-top: ${spacing.space40};
  max-width: 544px;
`;
